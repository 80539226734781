import { createContext } from 'react';

import config from '../../../core/config';

export type Props = {
  locale: string;
};

const I18nContext = createContext<Props>({
  locale: config.defaultLanguage,
});

export default I18nContext;
