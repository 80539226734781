import { MessageFormatElement } from '@formatjs/icu-messageformat-parser';
import React, { FC, PropsWithChildren, ReactElement, useContext } from 'react';

import { TranslationDictionary } from '@foodles/customer-app-lib/common/i18n';
import enTranslations from '@foodles/translations/customer-app/en.json';
import frTranslations from '@foodles/translations/customer-app/fr.json';

import I18nContext from '../I18nContext';
import WrappedIntlProvider from './WrappedIntlPovider';

const translations: Record<string, Record<string, MessageFormatElement[]>> = {
  fr: frTranslations,
  en: enTranslations,
};

export interface Props extends PropsWithChildren<{}> {
  localeMessages: TranslationDictionary;
}

const I18nProvider: FC<Props> = ({
  children,
  localeMessages,
}: Props): ReactElement => {
  const { locale } = useContext(I18nContext);
  const messages = localeMessages || translations[locale] || {};
  return (
    <WrappedIntlProvider
      locale={locale}
      messages={messages}
      defaultRichTextElements={{
        b: (chunks: React.ReactNode[]): ReactElement => <b>{chunks}</b>,
      }}
      textComponent={React.Fragment}
    >
      {children}
    </WrappedIntlProvider>
  );
};

export { default as RawI18nProvider } from './WrappedIntlPovider';

export default I18nProvider;
