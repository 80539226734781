import React, { ReactElement } from 'react';
import { TypeOptions } from 'react-toastify';

import Symbol from '@foodles/ui-components/Symbol';
import { Color } from '@foodles/ui-components/theme';

import { CloseButtonContainer } from './styledComponents';

export type Props = {
  closeToast?: Function;
  type?: TypeOptions;
};

const colorByType: { [type in TypeOptions]: Color } = {
  default: Color.LIGHT,
  info: Color.LIGHT,
  error: Color.LIGHT,
  success: Color.LIGHT,
  warning: Color.LIGHT,
};

const CloseButton = ({ closeToast, type }: Props): ReactElement => (
  <CloseButtonContainer role="button" onClick={(): void => closeToast!()}>
    <Symbol symbolKey="control/cross" scale={0.75} color={colorByType[type!]} />
  </CloseButtonContainer>
);

export default CloseButton;
