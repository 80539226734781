import { enGB, enUS, fr } from 'date-fns/locale';
import React, {
  ComponentProps,
  FC,
  PropsWithChildren,
  ReactElement,
} from 'react';
import { registerLocale } from 'react-datepicker';
import { IntlProvider } from 'react-intl';

export interface Props
  extends PropsWithChildren<ComponentProps<typeof IntlProvider>> {}

const WrappedIntlProvider: FC<Props> = ({
  children,
  ...intlProps
}: Props): ReactElement => {
  const lang = intlProps.locale.split('-')[0];

  registerLocale('fr', fr);
  registerLocale('fr-FR', fr);
  registerLocale('en', enUS);
  registerLocale('en-US', enUS);
  registerLocale('en-GB', enGB);
  return (
    <IntlProvider {...intlProps} locale={lang}>
      {children}
    </IntlProvider>
  );
};

export default WrappedIntlProvider;
