import { ApolloError } from 'apollo-client';
import { FormikHelpers } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { NextPageContext } from 'next';
import cookies from 'next-cookies';
import Router from 'next/router';
import { Dispatch, SetStateAction } from 'react';

import {
  SignUpErrorType,
  isSignUpErrorOfCertainType,
  mapErrorTypeToErrorCode,
} from '@foodles/customer-app-lib/authentication/helper';
import {
  SignInResult,
  SignUpResult,
} from '@foodles/customer-app-lib/authentication/hooks';
import {
  BaseSignUpFormValues,
  SignUpHandlerFormValues,
} from '@foodles/customer-app-lib/authentication/typings';
import { checkIsNetworkError } from '@foodles/customer-app-lib/common/helper/apollo';
import { ErrorCode } from '@foodles/customer-app-lib/common/typings';

import * as sentryHelper from '../core/helper/sentry';
import { Nullable } from '../core/typings';
import { Routes } from '../typings';
import { Role } from './typings';

interface SignUpUserParams
  extends Pick<SignUpResult, 'signUp'>,
    Pick<SignInResult, 'signIn'> {
  formValues: SignUpHandlerFormValues;
}

async function signUpUser({
  signUp,
  signIn,
  formValues,
}: SignUpUserParams): Promise<void> {
  const { email, password, firstName, lastName } = formValues;
  const success = await signUp({
    email,
    password,
    first_name: firstName,
    last_name: lastName,
    ...(formValues.registrationCode
      ? { registration_code: formValues.registrationCode }
      : {}),
  });

  if (success) {
    await signIn(email, password);
    await Router.push(Routes.SIGNUP_CONFIRMATION);
  }
}

interface HandleSignUpErrorParams {
  err: ApolloError;
  setSignUpError: Dispatch<SetStateAction<Nullable<ErrorCode>>>;
  formValues: BaseSignUpFormValues;
  errorType: SignUpErrorType;
}

function handleSignUpError({
  err,
  setSignUpError,
  formValues,
  errorType,
}: HandleSignUpErrorParams): void {
  if (isSignUpErrorOfCertainType(err, errorType)) {
    setSignUpError(mapErrorTypeToErrorCode[errorType]);
  } else if (checkIsNetworkError(err)) {
    setSignUpError(ErrorCode.NETWORK_REQUEST_ERROR_WEB);
  } else {
    setSignUpError(ErrorCode.AUTHENTICATION_ERROR);
    const { email, firstName, lastName } = formValues;
    sentryHelper.captureException(err, { email, firstName, lastName });
  }
}

interface SignUpHandlerParams
  extends Pick<SignUpUserParams, 'signIn' | 'signUp'>,
    Pick<HandleSignUpErrorParams, 'setSignUpError' | 'errorType'> {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export function handleSignUp({
  signUp,
  signIn,
  setSignUpError,
  setIsLoading,
  errorType,
}: SignUpHandlerParams): (
  formValues: SignUpHandlerFormValues,
  formikHelpers: FormikHelpers<SignUpHandlerFormValues>,
) => Promise<void> {
  return async function(
    formValues: SignUpHandlerFormValues,
    formikHelpers: FormikHelpers<SignUpHandlerFormValues>,
  ): Promise<void> {
    try {
      setIsLoading(true);
      await signUpUser({
        signUp,
        signIn,
        formValues,
      });
    } catch (err) {
      handleSignUpError({
        err,
        setSignUpError,
        formValues,
        errorType,
      });
    } finally {
      setIsLoading(false);
      formikHelpers.setSubmitting(false);
    }
  };
}

export function getUserRoles(ctx: NextPageContext): Role[] {
  const { ['user-roles']: roles } = cookies(ctx);
  if (!roles || isEmpty(roles)) return [];

  return roles.split(',') as Role[];
}
